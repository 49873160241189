export default {
    mutationLoadActiveProducts: (state, payload) => {
        state.activeProducts = payload;
    },

    mutationLoadActiveGroups: (state, payload) => {
        state.activeGroups = payload;
    },

    mutationLoadActiveCustomers: (state, payload) => {
        state.customers = payload;
    },

    mutationLoadActiveCustomerAreas: (state, payload) => {
        state.areas = payload;
    },

    mutationLoadLang: (state, payload) => {
        state.lang = payload;
    },

    mutationRefreshCartSubtotal: ( state, payload ) => {
        state.cartSubTotal = payload
    },

    mutationRefreshCartProducts: (state, payload) => {
        state.cartProducts = payload;
    },

    mutationProductFilters: (state, payload) => {
        for (let key in payload) {

            if(key === 'groupId'){
                // if product search is empty
                if (!state.temp.searchProduct){
                    // set null in product filter
                    Vue.set(state.productFilter, 'productName', null);
                }
            }

            Vue.set(state.productFilter, key, payload[key]);
        }
    },

    mutationProductIsLoading: (state, payload) => {
        state.productsIsLoading = payload;
    },

    mutationTempProductSearchCurrentValue: (state, payload) => {
        state.temp.searchProduct = payload
    }
}
