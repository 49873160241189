export default {
    activeGroups: [],
    activeProducts: [],
    cartProducts: [],
    purchaseCartProducts: [],
    cartSubTotal: null,
    productFilter: {},
    // filteredProductsFromServer: [], // fetch from server,
    areas: [],
    lang: {},
    customers: [],
    productsIsLoading: false,
    temp:{ // temp state
        searchProduct: null, // current value of search product
    }
};
