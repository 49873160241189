
window._ = require('lodash');

/**
 * We'll load jQuery and the Bootstrap jQuery plugin which provides support
 * for JavaScript based Bootstrap features such as modals and tabs. This
 * code may be modified to fit the specific needs of your application.
 */

try {
    window.Popper = require('popper.js').default;
    window.bootstrap = require('bootstrap');
    window.$ = require('jquery');
} catch (e) {
    console.log('error from bootstrap.js ', e)
}

/**
 * Alpine.js offers you the reactive and declarative nature of big frameworks like Vue or React at a much lower cost.
 * You get to keep your DOM, and sprinkle in behavior as you see fit.
 */

import 'alpinejs'

/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */

window.axios = require('axios');

window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

/**
 * Next we will register the CSRF Token as a common header with Axios so that
 * all outgoing HTTP requests automatically have it attached. This is just
 * a simple convenience so we don't have to attach every token manually.
 */

let token = document.head.querySelector('meta[name="csrf-token"]');

if (token) {
    window.axios.defaults.headers.common['X-CSRF-TOKEN'] = token.content;
} else {
    console.error('CSRF token not found: https://laravel.com/docs/csrf#csrf-x-csrf-token');
}

window.axios.interceptors.request.use( (config) => {
    // create and dispatch the event
    window.dispatchEvent(new CustomEvent("isAxiosRequesting", {
        detail: true
    }));
    return config
}, function (error) {
    // create and dispatch the event

    window.dispatchEvent(new CustomEvent("isAxiosRequesting", {
        detail: false
    }));

    return Promise.reject(error)
})


window.axios.interceptors.response.use((value) => {
    // create and dispatch the event
    window.dispatchEvent(new CustomEvent("isAxiosRequesting", {
        detail: false
    }));

    return value
}, function (error) {
    // create and dispatch the event
    window.dispatchEvent(new CustomEvent("isAxiosRequesting", {
        detail: false
    }));

    return Promise.reject(error)
})

/**
 * Echo exposes an expressive API for subscribing to channels and listening
 * for events that are broadcast by Laravel. Echo and event broadcasting
 * allows your team to easily build robust real-time web applications.
 */

// import Echo from 'laravel-echo';

// window.Pusher = require('pusher-js');

// window.Echo = new Echo({
//     broadcaster: 'pusher',
//     key: process.env.MIX_PUSHER_APP_KEY,
//     cluster: process.env.MIX_PUSHER_APP_CLUSTER,
//     forceTLS: true
// });
