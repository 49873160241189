import axios from 'axios';
export default {
    actionsLoadActiveCustomerAreas: ({ commit }) => {
        return new Promise((resolve, reject) => {
            // load all active groups
            axios
                .post(baseURL + "/dashboard/customer/get-all-active-customers-area")
                .then(response => {
                    commit("mutationLoadActiveCustomerAreas", response.data);
                    resolve()
                })
                .catch(error => {
                    console.log(error)
                    reject()
                })
        })
    },

    actionsLoadActiveCustomers: ({ commit }) => {
        return new Promise((resolve, reject) => {
            // load all active groups
            axios
                .post(baseURL + "/dashboard/customer/get-all-active-customers")
                .then(response => {
                    commit("mutationLoadActiveCustomers", response.data);
                    resolve()
                })
                .catch(error => {
                    console.log(error)
                    reject()
                })
        })
    },

    actionsLoadActiveGroups: ({ commit }) => {
        // load all active groups
        axios
            .post(baseURL + "/dashboard/group/get-all-active-groups")
            .then(response => {
                console.log(response.data)
                commit("mutationLoadActiveGroups", response.data);
            })
            .catch(error => {
                console.log(error)
            })
    },

    actionLoadActiveProducts: ({ commit }) => {
        //load all active products
        axios
            .post(baseURL + "/dashboard/pos/get-all-active-products")
            .then(response => {
                commit("mutationLoadActiveProducts", response.data);
                console.log(response.data)
            })
            .catch(error => {
                console.log(error);
            });
    },

    actionRemoveCartProduct: ({ commit, dispatch }, payload) => {
        return new Promise((resolve, reject) => {
            axios
                .post(baseURL + "/dashboard/cart/remove-cart-item", payload)
                .then(value => {
                    dispatch("actionRefreshCartSubTotal")
                        .then(response => {
                            commit("mutationRefreshCartProducts", value.data);
                            resolve();
                        })
                        .catch(reason => reject(reason));
                })
                .catch(reason => reject(reason));
        });

    },

    actionRefreshCartSubTotal: ({ commit }) => {
        return new Promise((resolve, reject) => {
            axios
                .post(baseURL + "/dashboard/cart/get-sub-total")
                .then(response => {
                    commit("mutationRefreshCartSubtotal", response.data);
                    resolve();
                })
                .catch(error => {
                    reject(error);
                });
        });
    },

    actionRefreshCartProducts: ({ commit, dispatch }) => {
        axios
            .post(baseURL + "/dashboard/cart/get-current-cart-items")
            .then(value => {
                dispatch("actionRefreshCartSubTotal")
                    .then(response => {
                        commit("mutationRefreshCartProducts", value.data);
                    })
                    .catch(reason => console.log(reason));
            })
            .catch(reason => console.log(reason));
    },

    actionFilterWiseProducts: ({ commit }, filters) => {
        return new Promise(async (resolve, reject) => {
            // show products preloader
            commit("mutationProductIsLoading", true);
            try {
                const response = await axios.post(
                    baseURL + "/dashboard/pos/filter-wise-products",
                    {
                        filters
                    }
                );
                commit("mutationLoadActiveProducts", response.data);
                resolve();
            } catch (reason) {
                console.log(reason);
                reject();
            }

            // hide products preloader
            commit("mutationProductIsLoading", false);
        });
    },

    actionClearCartItems: ({ commit, dispatch }) => {
        return new Promise((resolve, reject) => {
            axios.post(baseURL + '/dashboard/cart/clear-cart-items')
                .then(response => {
                    dispatch('actionRefreshCartProducts')
                    resolve()
                })
                .catch(reason => {
                    reject(reason)
                })
        })
    },

    actionLoadLang: ({ commit }) => {
        return new Promise((resolve, reject) => {
            axios.get(baseURL + '/dashboard/local/get-lang')
                .then(response => {
                    commit('mutationLoadLang', response.data)
                    resolve()
                })
                .catch(reason => {
                    reject(reason)
                })
        })
    }
}
