import Vue from "vue";
import vSelect from 'vue-select'

/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */
require('./bootstrap');

window.Vue = require('vue');

Vue.component('v-select', vSelect)
import 'vue-select/dist/vue-select.css';

import 'select2/dist/js/select2';
import 'select2/dist/css/select2.css';

/*
* add base url into vue
* */
Vue.prototype.baseURL = document.head.querySelector('meta[name="base-url"]').content + '';
window.baseURL = document.head.querySelector('meta[name="base-url"]').content + '';

// load view components
require('./components')

/**
 * Vuex initialization
 */
import store from './components/store/index'
Vue.use(store)

// Vue awesome notification
import VueAWN from "vue-awesome-notifications";
require("vue-awesome-notifications/dist/styles/style.css");
Vue.use(VueAWN);

//global event bus for vue component
window.eventBus = new Vue()

// import preloader
// import PreloaderComponent from "./components/preloader/PreloaderComponent";

const app = new Vue({
    el: '#app',
    store,
    // components: {
    //     PreloaderComponent
    // }
});
