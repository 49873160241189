export default {
    getterSingleActiveProduct: state => barcode => {
        return state.activeProducts.find(
            product => product.barcode === barcode
        );
    },

    getterSingleCartItem: state => productId => {
        return state.cartProducts[productId];
    },

    getterSortedCartProducts: state => {
        const data = state.cartProducts
        const _sale_items_value = Object.keys(data).map((key) => data[key])

        _sale_items_value
            .sort(function (a, b) {
                return b.attributes.meta.created_at - a.attributes.meta.created_at
            })
        return _sale_items_value

    },

    getterSingleActiveProductByProductCode: state => productCode => {
        return state.activeProducts.find(
            product => product.code === productCode
        );
    },

    getterFilterWiseProducts: state => payload => {
        if (payload.groupId !== null) {
            return state.activeProducts.filter(
                product => product["group_id"] == payload["groupId"]
            );
        } else if (
            payload.productName != null &&
            payload.productName.length > 0
        ) {
            return state.activeProducts.filter(product =>
                product.name
                    .toLowerCase()
                    .includes(payload.productName.toLowerCase())
            );
        } else {
            return state.activeProducts;
        }
    }
}
